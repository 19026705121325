import React from "react";

import { PageProps } from "gatsby";
import { Ref } from "react";

import { forwardRef } from "react";
import { useContext } from "react";
import { useLayoutEffect } from "react";

import ThemeContext from "@Js/Context/ThemeContext";

import Seo from "@Js/Component/Seo";

import Gutenberg from "@Js/Component/Gutenberg";

/**
 * @type NewPageProps
 */
export type NewPageProps = PageProps & {
    pageContext: {
        pageEdge: {
            node: {
                content?: string;
                seo: {
                    canonical: string;
                    metaDesc: string;
                    metaKeywords: string;
                    metaRobotsNofollow: string;
                    metaRobotsNoindex: string;
                    opengraphAuthor: string;
                    opengraphDescription: string;
                    opengraphModifiedTime: string;
                    opengraphPublishedTime: string;
                    opengraphPublisher: string;
                    opengraphSiteName: string;
                    opengraphTitle: string;
                    opengraphType: string;
                    opengraphUrl: string;
                    schema: {
                        raw: string;
                    };
                    title: string;
                };
            };
        };
    };
};

/**
 * @const Page
 */
const Page = (props: NewPageProps, ref: Ref<HTMLElement>) => {
    const {
        pageContext
    } = props;

    const {
        setHeaderBackground,
        setIsLogoColorless,
        setIsMenuColorless
    } = useContext(ThemeContext);

    useLayoutEffect(() => {
        setHeaderBackground("#FFFFFF");

        setIsLogoColorless(false);
        setIsMenuColorless(false);
    }, []);

    return (
        <>
            <Seo { ...pageContext.pageEdge.node.seo } />
            <section ref={ ref }>
                { pageContext.pageEdge.node.content && (
                    <Gutenberg content={ pageContext.pageEdge.node.content } />
                ) }
            </section>
        </>
    );
};

export default forwardRef(Page);
